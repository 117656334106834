'use strict';

$(document).ready(function(){
    animateDiv('.yes-btn');


    $('#page2-btn-book-dates').click(function () {
        if (this.id == 'page2-btn-book-dates') {
            location.href='p378478347343.html';
        }
    });

    $('#page4-btn-start-planning').click(function () {
        if (this.id == 'page4-btn-start-planning') {
            $("#page4-btn-start-planning").css({'background-color':'black'})
            $( "#page4-btn-start-planning" ).animate({
                width: "100%",
                marginLeft: "0",
                marginRight: "0",
              }, 500, function() {
                $("body").css({'background-color':'black'})
                setTimeout(function(){ 
                    location.href='p289821988965.html';
                 }, 100);
            });
        }
        
    });

    if($("#page05").is(":visible")){
        $( "#page05container" ).animate({
            opacity: "100",
          }, 6000);
    } 
});

function myFunction() {
    setTimeout(function(){ 
        location.href='p289821988965.html';
     }, 3000);
}

function makeNewPosition(){
    
    // Get viewport dimensions (remove the dimension of the div)
    var h = $(window).height() - 100;
    var w = $(window).width() - 155;
    
    var nh = Math.floor(Math.random() * h);
    var nw = Math.floor(Math.random() * w);
    
    return [nh,nw];    
    
}

function animateDiv(myclass){
    var newq = makeNewPosition();
    $(myclass).animate({ top: newq[0], left: newq[1] }, 1000,   function(){
      animateDiv(myclass);        
    });
    
};